import React, { FC } from 'react';
import styled from 'styled-components';
import CardContainer from './CardContainer';
import TickIcon from 'assets/icons/tick-circle.svg';

import { Text } from 'components';
import { smMobile, tablet } from 'styles/breakpoints';
import { ImageWithContainer } from '..';

interface ListProps {
  title: string;
  list: string[];
  isNumberedList?: boolean;
  padding?: string;
  cardBgColor?: string;
  maxWidth?: string;
}

const Container = styled.div<{ padding: string; bgColor?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f3f3f8;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const ContentContainer = styled.div<{ maxWidth?: string }>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '30.5rem')};
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.25rem;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 36.7rem;
  margin-bottom: 0.75rem;
`;

const Item = styled(Text)`
  color: #1c1c28;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-left: 1rem;
  span {
    color: #000;
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const CardContainerStyled = styled(CardContainer)<{ cardBgColor?: string }>`
  background-color: #fff;
  border: 1px solid #e6e6f1;
  padding: 2.25rem 3rem;
  margin: 0;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3.8265rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const Number = styled(Text)`
  color: #fff;
  font-size: 0.91838rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const ListItem = ({
  item,
  isNumberedList,
  number,
}: {
  item: string;
  isNumberedList: boolean;
  number: number;
}) => (
  <ListItemContainer>
    <IconContainer>
      {isNumberedList ? (
        <Circle>
          <Number>{number}</Number>
        </Circle>
      ) : (
        <TickIcon width={24} height={24} fill="#05A56F" />
      )}
    </IconContainer>
    <Item dangerouslySetInnerHTML={{ __html: item }} />
  </ListItemContainer>
);

const List: FC<ListProps> = ({
  title,
  list,
  isNumberedList = false,
  padding = '0',
  cardBgColor,
  maxWidth,
  ...props
}) => {
  const renderListItem = (item: string, index: number) => (
    <ListItem
      item={item}
      key={index}
      isNumberedList={isNumberedList}
      number={index + 1}
    />
  );
  return (
    <Container padding={padding} {...props}>
      <ContentContainer maxWidth={maxWidth}>
        <CardContainerStyled cardBgColor={cardBgColor}>
          <Title>{title}</Title>
          {list?.map(renderListItem)}
        </CardContainerStyled>
      </ContentContainer>
    </Container>
  );
};

export default List;
